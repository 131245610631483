@font-face {
  font-family: 'iconfont'; /* project id 1150292 */
  src: url('//at.alicdn.com/t/font_1150292_gb6qrh2cjeq.eot');
  src:
    url('//at.alicdn.com/t/font_1150292_gb6qrh2cjeq.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1150292_gb6qrh2cjeq.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1150292_gb6qrh2cjeq.woff') format('woff'),
    url('//at.alicdn.com/t/font_1150292_gb6qrh2cjeq.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1150292_gb6qrh2cjeq.svg#iconfont') format('svg');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
